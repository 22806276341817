import { fetchAjaxFormSender, fetchValidator } from '../dynamic-modules';

const SELECTOR = '.js-ajax-form';
const map = new WeakMap<HTMLFormElement, Record<string, any>>();

const showMessage = (form: HTMLFormElement, message = '') => {
    const successMessage = form.querySelector('.js-form-message');
    form.classList.add('is-open');
    let messageModal = form.querySelector('[data-form-thanks-page]');
    if (messageModal) {
        messageModal.classList.add('is-open')
    }

    if (successMessage) {
        successMessage.innerHTML = message;
    }
};

const showFormMessages = (form: HTMLFormElement) => {
    const messagesContainer = form.querySelector('.js-form-messages');
    if (messagesContainer) {
        messagesContainer.classList.remove('form-messages--hidden');
    }
};

const clearAntispamInput = (form: HTMLFormElement) => {
    const checkInput = form.querySelector('input.js-form__check-val') as HTMLInputElement | null;
    if (checkInput) {
        checkInput.value = '';
    }
};

async function init(container: Element | Document = document) {
    const forms = Array.from(container.querySelectorAll(SELECTOR)) as HTMLFormElement[];
    if (forms.length > 0) {
        const [{ default: createFormSender }, { default: createValidator, clearMessages }] = await Promise.all([
            fetchAjaxFormSender(),
            fetchValidator(),
        ]);

        forms.forEach((form) => {
            const validator = createValidator(form);
            let data = {};
            const sender = createFormSender(form, {
                shouldClearInputs: false,
                method: 'post',
                onBeforeSend: () => {
                    clearAntispamInput(form);
                },
                data,
                onSuccess: ({alertMassage, clientScriptToExecute, status}) => {
                    if(typeof  clientScriptToExecute !== 'undefined' && clientScriptToExecute != null)
                    {
                        try {
                            eval(clientScriptToExecute);
                        } catch
                        {
                        }
                    }
                    showMessage(form, alertMassage);
                },
                onError: (res) => {
                    showMessage(form, message);
                },
                onComplete: () => {
                    clearMessages(form);
                    showFormMessages(form);
                },
            });
            map.set(form, sender);

            form.addEventListener('submit', (event) => {
                event.preventDefault();
                const isFormValid = validator.validate();
                if (isFormValid) {
                    sender.send();
                }
            });
        });
    }
}

function destroy(container: Element | Document = document) {
    //
}

function getInstanceByElement(element: HTMLFormElement) {
    return map.get(element);
}

const _module = { init, destroy, getInstanceByElement };

export default _module;
