export default function observeMouse() {
    let container = document.querySelector('.js-wrapper-main');

    if (!container) {return}
    let isMobile = false;

    if (window.innerWidth < 1170) {
        isMobile = true;
    }

    let width, cardWidth, offset,
        card = document.querySelector('.js-card-mask'),
        observeSection = document.querySelector('.js-mouse-observe'),
        isMasking = false;
    
    if (!card || !observeSection) {
        return
    }

    function getData() {
        width = window.innerWidth;
        cardWidth = card.offsetWidth;
        offset = (width - cardWidth) / 2;
    }


    getData()

    window.addEventListener('resize', function() {
        if (window.innerWidth < 1170) {
            isMobile = true;
        }
        getData()
    });

        if (!isMobile) {
            observeSection.addEventListener('mouseenter', function(e) {
                isMasking = true;
            });
    
            observeSection.addEventListener('mouseleave', function(e) {
                isMasking = false;
            });
    
            observeSection.addEventListener('mousemove', function(e) {
                if (isMasking) {
                    card.style.cssText = `-webkit-clip-path:polygon(0% 101%, 0% 0%, ${Math.round((e.clientX - offset) / cardWidth * 100)}% 0%, ${Math.round((e.clientX - offset) / cardWidth * 100)}% 101%); clip-path:polygon(0% 101%, 0% 0%, ${Math.round((e.clientX - offset) / cardWidth * 100)}% 0%, ${Math.round((e.clientX - offset) / cardWidth * 100)}% 101%)`;
                }
            });
        } else {
             observeSection.addEventListener('touchmove', function(e) {
                card.style.cssText = `-webkit-clip-path:polygon(0% 101%, 0% 0%, ${Math.round((e.touches[0].pageX - offset) / cardWidth * 100)}% 0%, ${Math.round((e.touches[0].pageX - offset) / cardWidth * 100)}% 101%); clip-path:polygon(0% 101%, 0% 0%, ${Math.round((e.touches[0].pageX - offset) / cardWidth * 100)}% 0%, ${Math.round((e.touches[0].pageX - offset) / cardWidth * 100)}% 101%)`;
            });
        }

       
}
