
import slider from '../inits/slider';

export default function ajaxLoader( selector ) {

    let container = document.querySelector(selector);

    container.classList.add('is-loading');
    const url = "../php/get-cases_json.php?";
    const casesList = document.querySelector('.cases__list');
    let htmlOfItems, htmlBeforeSlides, htmlOfSlides, htmlAfterSlides;
    htmlOfItems = '';
    htmlOfSlides = '';
    htmlBeforeSlides = `<div class="cases__slider" id="cases-slider">
    <div class="cases__bg" data-drop-toggle="cases-slider"></div>
    <div class="glide">
        <div class="glide__track" data-glide-el="track">
            <ul class="glide__slides">`;
    htmlAfterSlides = `
                        </ul>
                            <div class="glide__arrows cases-control">
                                <button class="palette-btn glide__arrow glide__arrow--left" data-glide-dir="<">
                                    <span class="icon--small btn btn--light btn--svg-stroke">
                                        <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.59473 12.1292L2.00002 6.52238L7.59473 0.999895" stroke="#343739" stroke-width="2"/>
                                        </svg>
                                    </span>
                                </button>
                                <button class="palette-btn glide__arrow glide__arrow--right" data-glide-dir=">">
                                    <span class="icon--small btn btn--light btn--svg-stroke">
                                        <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 1L6.59471 6.60677L1 12.1293" stroke="#343739" stroke-width="2"/>
                                        </svg>
                                    </span>
                                </button>
                                <button class="palette-btn">
                                    <span class="icon--small btn btn--light btn--svg-stroke js-slider-close"
                                        data-drop-toggle="cases-slider">
                                        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.682 4.81795L4.31805 11.1819" stroke="#343739" stroke-width="1.5" stroke-linecap="square"/>
                                            <path d="M4.31807 4.81787L10.682 11.1818" stroke="#343739" stroke-width="1.5" stroke-linecap="square"/>
                                        </svg>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>`;
    fetch(url)
    .then(response => response.json())
    .then(json => {
        for (let data of json) {
            htmlOfItems += `
            <div class="cases__item">
                <div class="cases__wrapper">
                    <picture>
                        <source srcset="${data.img}"
                            type="image/webp">
                        <img src="${data.img}" class="cases__img lazy" loading="eager" alt="${data.title}"
                            data-drop-toggle="cases-slider" data-slider="cases__slider"
                            data-slide="${data.index}" data-lock-scroll>
                    </picture>
                </div>
                <div class="cases__title">${data.title}</div>
                <div class="cases__text">${data.text}</div>
                <div class="cases__more">
                    <div class="more">
                        <div class="more__link" data-drop-toggle="cases-slider" data-slider="cases__slider"
                            data-slide="${data.index}" data-lock-scroll>
                            <div class="more__text">подробнее</div>
                            <svg width="41" height="28" viewBox="0 0 41 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22.8202 0.634766L39.6437 13.5142L22.8202 26.6348" stroke="#343739" stroke-width="1.5"/>
                                <path d="M37.8202 13.6348H1.82019" stroke="#343739" stroke-width="1.5" stroke-linecap="square"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>`;
            htmlOfSlides += `
                            <li class="glide__slide custom-scroll">

                                <div class="cases__head">
                                    <div class="cases__headling">
                                        <div class="cases__name">
                                            ${data.title}
                                        </div>
                                        <div class="cases__type">
                                            ${data.text}
                                        </div>
                                    </div>
                                    <div class="cases__profit">
                                        <div class="cases__profit-num">
                                            +${data.profit}%
                                        </div>
                                        <div class="cases__profit-text">
                                            к выручке
                                        </div>
                                    </div>

                                </div>
                                <picture>
                                    <source srcset="${data.img_big}?resize=1102x&format=webp"
                                        type="image/webp" media="(min-width: 769px)">
                                    <source srcset="${data.img_big_mobile}?resize=280x&format=webp"
                                        type="image/webp" media="(max-width: 768px)">
                                    <img class="cases__img--detal lazy" ≠src="${data.img_big }" loading="eager"
                                        alt="${data.title}">
                                </picture>
                                <div class="cases__table">
                                    <div class="table">
                                        <div class="table__head">
                                            <div class="table__row">
                                                <div class="table__col">
                                                    <div class="table__left table__title">
                                                        Используемый тип карт:
                                                    </div>
                                                    <div class="table__left table__description">
                                                        номер телефона в качестве карты лояльности
                                                    </div>
                                                </div>
                                                <div class="table__col">
                                                    <div class="table__right table__title">
                                                        категории карт:
                                                    </div>
                                                    <div class="table__right">
                                                        <div class="types">
                                                            <div class="types__list">
                                                                <div class="types__item">
                                                                    <div class="btn btn--dngr not-btn">
                                                                        <span>стандартная</span>
                                                                    </div>
                                                                </div>
                                                                <div class="types__item">
                                                                    <div class="btn btn--light not-btn">
                                                                        <span>серебряная</span>
                                                                    </div>
                                                                </div>
                                                                <div class="types__item">
                                                                    <div class="btn btn--ctrl not-btn">
                                                                        <span>золотая</span>
                                                                    </div>
                                                                </div>
                                                                <div class="types__item">
                                                                    <div class="btn btn--main not-btn">
                                                                        <span>платиновая</span>
                                                                    </div>
                                                                </div>
                                                                <div class="types__item">
                                                                    <div class="btn btn--dark not-btn">
                                                                        <span>vip</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="table__body">
                                            <div class="table__row">
                                                <div class="table__left table__title">
                                                    использование бонусов:
                                                </div>
                                                <div class="table__right">
                                                    <ul class="table__list">
                                                        <li class="table__list-item">
                                                            доступны на следующий день после начисления
                                                        </li>
                                                        <li class="table__list-item">
                                                            можно оплатить до 20% от суммы покупок
                                                        </li>
                                                        <li class="table__list-item">
                                                            сгорают через 60 дней
                                                            с момента начисления
                                                            с предварительным СМС уведомлением за 14 дней
                                                        </li>
                                                        <li class="table__list-item">
                                                            при частичном расчете бонусами, новые бонусы
                                                            начисляются
                                                            на сумму покупки,
                                                            оплаченную рублями
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="table__row">
                                                <div class="table__left table__title">
                                                    дополнительные опции:
                                                </div>
                                                <div class="table__right">
                                                    <ul class="table__list">
                                                        <li class="table__list-item">
                                                            пригласи друга и получай бонусы с его покупок
                                                            (за каждые 50 ₽, потраченные приведенным
                                                            клиентом,
                                                            1 бонус начисляется клиенту,
                                                            по рекмоендации которого, пришел клиент)
                                                        </li>
                                                        <li class="table__list-item">
                                                            за 14 дней до дня рождения, начисляется 250
                                                            подарочных бонусов со сроком действия 21 день
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </li>`;



            let casesListHtml = htmlOfItems + htmlBeforeSlides + htmlOfSlides + htmlAfterSlides;

            casesList.innerHTML = casesListHtml;
        }
        slider.init('cases__slider')
    })
    .finally(()=>{
        container.classList.remove('is-loading');
    })
}