import slider from '../inits/slider'
import delegate from 'delegate'

export default function Modal( element ) {
    let API = {},
        setting = {},
        body = document.querySelector('body'),
        html = document.querySelector('html'),
        header = document.querySelector('.header'),
        burger = document.querySelector('.js-burger'),
        palette = document.querySelector('.palette-slider');
    if (!element) {
        return
    }

    let isMobile = false;

    if (window.innerWidth < 1170) {
        isMobile = true;
    }

    window.addEventListener('resize', function() {
        if (window.innerWidth < 1170) {
            isMobile = true;
        }
    });

    function init() {
        let selector = element.getAttribute('data-drop-toggle');

        if (!selector) {
            selector = element.getAttribute('href');
        }

        if (selector) {
            setting.container = document.getElementById(selector);
        } else {
            setting.container = element.parent();
        }
        setting.selector = selector;
        setting.container.isOpen = false;
        setting.container.isAnim = false;
    }
    function show(event) {
        if (isMobile) {
            if (event.target.getAttribute('data-burger') !== null) {
                header.classList.add('is-burger')
            }
        }
        if (event.target.getAttribute('data-slide')) {
            setTimeout(() => {
                slider.update(event.target.getAttribute('data-slider'),event.target.getAttribute('data-slide'));
                document.getElementById(element.dataset.dropToggle).classList.add('is-open');
            }, 100)
        }
        if (element.dataset.dropToggle == 'palette-slider') {
            console.log(1)
            if (window.matchMedia('(max-width: 768px)').matches) {
                window.scrollTo({
                    top: palette.getBoundingClientRect().top + pageYOffset - 90,
                    behavior: "smooth"
                });
            }
        }
        if (event.target.getAttribute('data-lock-scroll') !== null){
            body.classList.add('scroll-lock')
            html.classList.add('scroll-lock')
        }
        setting.container.isOpen = true;
        setting.container.isAnim = true;
        setting.container.classList.add('is-open');
        element.classList.add('is-open');
        setTimeout(function() {
            setting.container.isAnim = false;
        }, 400)
    }

    function hide(event) {
        if (isMobile) {
            if (element.getAttribute('data-burger') !== null) {
                header.classList.remove('is-burger')
                burger.classList.remove('is-open')
            }
        }
        if (element.dataset.dropToggle == 'cases-slider') {
            document.getElementById(element.dataset.dropToggle).classList.remove('is-open');
        }
        setting.container.classList.add('is-out');
        setting.container.isAnim = true;
        setTimeout(function () {
            setting.container.classList.remove('is-out');
            setting.container.isOpen = false;
            setting.container.classList.remove('is-open');
            element.classList.remove('is-open');
            setting.container.isAnim = false;
            body.classList.remove('scroll-lock');
            html.classList.remove('scroll-lock');
        }, 400)
    }

    function toggle(event) {
        if (element.dataset.mobile == null) {
            event.preventDefault();
        }
        event.stopPropagation();
        if (setting.container.isAnim) {return}

        if (setting.container.isOpen) {
            hide(event);
        } else {
            show(event);
        }
    }


    init();

    window.addEventListener('keyup', function (e) {
        if (e.keyCode == '27') {
            hide(event);
        }
    });
    window.addEventListener("hide", function(event) { // (1)
        hide(event);
    });

    delegate(document.body, `[data-drop-toggle="${element.getAttribute('data-drop-toggle')}"]`, 'click', function(e) {
        toggle.call(e.delegateTarget, e);
    }, false);

    window.hide = hide;
    API.hide = hide;
    return API;
}

