import './webpack-imports';
import './sentry';
import './polyfills';
import IMask from 'imask';
import Modal from './components/modal';
import Accordeon from './components/accordeon';
import Tab from './components/tab';
import observeMouse from './components/observeMouse';
import slider from './inits/slider';
import cases from './inits/cases';
import video from './inits/video';
import toggleRadio from './components/toggleRadio';
// import anchors from './components/anchors';
import percent from './components/percent';
import state from './components/state';
import validate from './components/validate';
import calc from './components/calc2';
// import graph from './components/graph';
import anchors from './components/anchors';
import data from './components/getRect';
import collapse from './components/collapse';
import intersection from './components/intersection';
import LazyLoad from "vanilla-lazyload";
import { clearAll } from 'flat-cache';
import Collapse from 'bootstrap.native/dist/bootstrap-native.min'
import loadCompanyData from './inits/load-company-data'
import ajaxForms from './inits/ajax-forms';

Collapse
calc();
let isMobile = false;

if (window.innerWidth < 1170) {
    isMobile = true;
}
let isNotWide = false;

if (window.innerWidth < 1023) {
    isNotWide = true;
}

loadCompanyData.init();



var lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy"
});

const halfYearDicount = document.querySelector('.js-half-year-discount');
const yearDicount = document.querySelector('.js-year-discount');


document.addEventListener('DOMContentLoaded', () => {
    ajaxForms.init();

    let spoilers = document.querySelectorAll('.spoiler');

    spoilers.forEach(item => {
        item.classList.add('is-in');
        setTimeout(()=> {
            item.classList.remove('is-in');
        },1500)
    })

    document.addEventListener('smartChoose', () => {
        if (halfYearDicount) {
            halfYearDicount.innerHTML = '-5%';
        }
        if (yearDicount) {
            yearDicount.innerHTML = '-10%';
        }
    })
    document.addEventListener('notSmartChoose', () => {
        if (halfYearDicount) {
            halfYearDicount.innerHTML = '-15%';
        }
        if (yearDicount) {
            yearDicount.innerHTML = '-30%';
        }
    })

    intersection()
    percent();
    document.documentElement.classList.add('js-ready');

    const container = document.querySelector('.js-wrapper-main');

    let slidersArray = ['palette-slider', 'js-slider-alcohol', 'js-slider-beauty', 'js-slider-clothes', 'js-slider-food', 'js-slider-other' , 'tarif'];

    if (!container) {
        slidersArray = ['js-slider-alcohol', 'js-slider-beauty', 'js-slider-clothes', 'js-slider-food', 'js-slider-other' ];
    }


    slidersArray.forEach((item) => {
        slider.init(item);
    });

    cases.init('.cases', '.js-filters-cases', '.filters__item', '.cases__list');

    const phoneInputs = Array.from(document.querySelectorAll('input[type="tel"]'));
    phoneInputs.forEach((item) => {
        IMask(item, { mask: '+{7}(000)000-00-00' });
    });
});


const accordeonLinks = Array.from(document.querySelectorAll('[data-accordeon-group]'));

accordeonLinks.forEach(link => {
    link = new Accordeon(link);
})

const links = Array.from(document.querySelectorAll('[data-drop-toggle]'));

links.forEach(link => {
    link = new Modal(link);
});

const tabsCollection = document.querySelectorAll('[data-tab-list]');
const tabs = Array.from(tabsCollection);



observeMouse();




video();

const radio = Array.from(document.querySelectorAll('.js-radio'));

radio.forEach((item) => {
    item.addEventListener('click', (e) => {
        toggleRadio(e);
    });
});

const anchorLinks = Array.from(document.querySelectorAll('.js-anchor'));
let tryFree = document.querySelector('.try-free');

anchorLinks.forEach((item) => {
    item.addEventListener('click', (event) => {

        event.stopPropagation();
        if (item.getAttribute(`data-direction`) == 'outer') {
            return
        }

        const target = event.target.getAttribute('href'),
            element = document.querySelector(target),
            color = element.getAttribute('data-bg-color'),
            header = document.querySelector('.header'),
            burger = document.querySelector('.js-burger');

        if (event.target.getAttribute('data-burger') !== null) {
            let event = new Event("hide", {bubbles: true}); // (2)
            document.dispatchEvent(event);
            header.classList.remove('is-burger')
        }
    });
});

document.addEventListener('scroll', () => {
    percent();
});


tabs.forEach((tab) => {
    tab = new Tab(tab);
});
