export default function Tab(container){
    let currentTab = container.querySelector('.js-tab-content.is-in'),
        links = Array.from(container.querySelectorAll('.js-tab-control .js-tab-link')),
        currentLink = links[0],
        oldLink = currentLink,
        oldTab = currentTab;
    let smartEvent = new Event("smartChoose"),
        notSmartEvent = new Event("notSmartChoose");
    links.map(function(link){
        link.addEventListener('click', function(e) {
            e.preventDefault();
            oldLink = currentLink;
            currentLink = this;
            oldLink.classList.remove('is-active');
            currentLink.classList.add('is-active');
            oldTab = currentTab;
            currentTab = container.querySelector(this.getAttribute('href'));
            oldTab.classList.remove('is-in');
            oldTab.classList.add('is-out');
            oldTab.classList.remove('is-out')
            currentTab.classList.add('is-in');
            currentTab.dataset.groupId == 'smart' ?
                                        document.dispatchEvent(smartEvent) :
                                        document.dispatchEvent(notSmartEvent)
        })
    })

}