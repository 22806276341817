import Glide from '@glidejs/glide';

const container = document.querySelector('.js-wrapper-main');

function init(slider) {
    const sliderContainer = document.querySelector(`.${slider}`);

    if (!sliderContainer) return;

    const glide = new Glide(sliderContainer, {
        type: 'carousel',
        focusAt: 'center',
        perView: 1,
        perSwipe: 1,
        arrows: true,
        gap: 0,
        animationDuration: 0,
    });

    sliderContainer.glide = glide;

    const event = new Event('resize');

    const sliderEl = document.querySelector(`.${slider}`);
    const controls = Array.from(sliderEl.querySelectorAll('[data-glide-dir]'));

    controls.forEach((item) => {
        item.addEventListener('click', () => {
            glide.go(`${item.getAttribute('data-glide-dir')}`);
        });
    });

    glide.on('swipe.end', () => {
        setTimeout(() => {
            window.dispatchEvent(event);
        }, 100);
    });

    if (slider === 'palette-slider') {
        let curElement = document.querySelector('.js-slider-cur'),
            totalElement = document.querySelector('.js-slider-total');

        glide.on('mount.after', function() {
            totalElement.innerHTML = glide._c.Html.slides.length;
            curElement.innerHTML = 1;
        });

        glide.on('run.after', function() {
            curElement.innerHTML = glide.index + 1;
        });
    }
    setTimeout(function() {
        glide.mount();
    }, 100);
}

function update(slider, slide) {
    let curElement = document.querySelector('.js-slider-cur'),
        selector = document.querySelector(`.${slider}`);

    if (container) {
        selector.glide.on('update', function() {
            curElement.innerHTML = parseInt(slide) + 1;
        });
    }

    setTimeout(function() {
        selector.glide.update({
            startAt: slide,
        });
    }, 1);
}

const module = { init, update };

export default module;
