import Vue from 'vue/dist/vue.min.js';
import {
    BCollapse,
    CollapsePlugin
} from 'bootstrap-vue';
Vue.use(CollapsePlugin);

Vue.component('b-collapse', BCollapse);

export default function calc2() {

    let app = new Vue({
        el: '.order',
        delimiters: ['[[', ']]'],
        data: {
            width: null,
            period: 'month',
            cardPeriod: 'month',
            total: 9000,
            checkboxes: {
                system_without: {
                    name: 'Без смарт-терминалов',
                    price: {
                        month: 9000,
                        halfYear: 46500,
                        year: 76500
                    },
                    checked: true,
                    currentPrice: 9000,
                    isSmart: false,
                    isMain: true,
                    group: 'period'
                },
                smart_evotar: {
                    name: 'Эвотор',
                    tarifs: {
                        standart: {
                            month: 1240,
                            monthAdd: 390,
                            halfYear: 7070,
                            halfYearAdd: 2223,
                            year: 13340,
                            yearAdd: 4212
                        },
                        profi: {
                            month: 1760,
                            monthAdd: 260,
                            halfYear: 10040,
                            halfYearAdd: 1482,
                            year: 18960,
                            yearAdd: 2808
                        },
                        profiplus: {
                            month: 2210,
                            monthAdd: 260,
                            halfYear: 12600,
                            halfYearAdd: 1482,
                            year: 23870,
                            yearAdd: 2808
                        }
                    },
                    curTarif: 'standart',
                    cassCount: 1,
                    checked: false,
                    currentPrice: 0,
                    isSmart: true,
                    isMain: true,
                    group: 'period'
                },
                smart_modul: {
                    name: 'МодульКасса',
                    tarifs: {
                        standart: {
                            month: 1240,
                            monthAdd: 390,
                            halfYear: 7070,
                            halfYearAdd: 2223,
                            year: 13340,
                            yearAdd: 4212
                        },
                        profi: {
                            month: 1760,
                            monthAdd: 260,
                            halfYear: 10040,
                            halfYearAdd: 1482,
                            year: 18960,
                            yearAdd: 2808
                        },
                        profiplus: {
                            month: 2210,
                            monthAdd: 260,
                            halfYear: 12600,
                            halfYearAdd: 1482,
                            year: 23870,
                            yearAdd: 2808
                        }
                    },
                    curTarif: 'standart',
                    cassCount: 1,
                    checked: false,
                    currentPrice: 0,
                    isSmart: true,
                    isMain: true,
                    group: 'period'
                },
                system_cms: {
                    name: '1с-Битрикс',
                    price: {
                        month: 3000,
                        halfYear: 15500,
                        year: 25550
                    },
                    checked: false,
                    currentPrice: 0,
                    isSmart: false,
                    isMain: true,
                    group: 'period'
                },
                integration_1s: {
                    name: 'Интеграция в 1с',
                    price: 10000,
                    checked: false,
                    currentPrice: 0,
                    isSmart: false,
                    isMain: false,
                    group: 'once'
                },
                integration_1s_bitrix: {
                    name: 'Интеграция в 1с-Битрикс',
                    price: 25000,
                    checked: false,
                    currentPrice: 0,
                    isSmart: false,
                    isMain: false,
                    group: 'once'
                },
            }
        },
        methods: {
            calcCassCount($event) {
                let val = $event.target.value;
                if (val < 1) {
                    val = 1;
                }
                $event.target.value = val;
                this.checkboxes[$event.target.dataset.name].cassCount = val;
                this.discount();
            },
            calcTarif($event) {
                this.checkboxes[$event.target.name].curTarif = $event.target.value;
                this.discount()
            },
            calcPeriod($event) {
                this.period = $event.target.value
                this.discount();
            },
            calcCardPeriod($event) {
                this.cardPeriod = $event.target.value
                this.discount();
            },
            discount() {
                let arr = [],
                    isFirstDiscounted = false;
                Object.keys(this.checkboxes).forEach((key, index) => {
                    /*if (this.isMoreThenOne) {
                        f (this.isSmartSelected) {
                            if (this.checkboxes[key].checked) {
                                if (this.checkboxes[key].isSmart) {
                                    this.checkboxes[key].currentPrice = this.checkboxes[key].tarifs[this
                                        .checkboxes[key].curTarif][this.period] + this.checkboxes[
                                        key].tarifs[this.checkboxes[key].curTarif][
                                        `${this.period}Add`
                                    ] * (this.checkboxes[key].cassCount - 1);
                                } else {
                                    if (this.checkboxes[key].isMain) {
                                        this.checkboxes[key].currentPrice = this.checkboxes[key].price[
                                            this.period] * 0.5;
                                    } else {
                                        if (this.checkboxes[key].isCard) {
                                            this.checkboxes[key].currentPrice = this.checkboxes[key]
                                                .price[this.cardPeriod];
                                        } else {
                                            this.checkboxes[key].currentPrice = this.checkboxes[key]
                                                .price;
                                        }
                                    }
                                }
                                arr.push(this.checkboxes[key].currentPrice)
                            } else {
                                this.checkboxes[key].currentPrice = 0;
                            }
                        } else {
                            if (this.checkboxes[key].checked) {
                                if (!isFirstDiscounted) {
                                    this.checkboxes[key].currentPrice = this.checkboxes[key].price[this
                                        .period];
                                    isFirstDiscounted = true;
                                } else {
                                    if (this.checkboxes[key].isMain) {
                                        this.checkboxes[key].currentPrice = this.checkboxes[key].price[
                                            this.period] * 0.5;
                                    } else {
                                        if (this.checkboxes[key].isCard) {
                                            this.checkboxes[key].currentPrice = this.checkboxes[key]
                                                .price[this.cardPeriod];
                                        } else {
                                            this.checkboxes[key].currentPrice = this.checkboxes[key]
                                                .price;
                                        }
                                    }
                                }
                                arr.push(this.checkboxes[key].currentPrice)
                            } else {
                                this.checkboxes[key].currentPrice = 0;
                            }
                        }
                    } else {
                        */
                        if (this.checkboxes[key].checked) {
                            if (this.checkboxes[key].isMain) {
                                if (this.checkboxes[key].isSmart) {
                                    this.checkboxes[key].currentPrice = this.checkboxes[key].tarifs[this
                                        .checkboxes[key].curTarif][this.period] + this.checkboxes[
                                        key].tarifs[this.checkboxes[key].curTarif][
                                        `${this.period}Add`
                                    ] * (this.checkboxes[key].cassCount - 1);
                                } else {
                                    this.checkboxes[key].currentPrice = this.checkboxes[key].price[this
                                        .period];
                                }
                            } else {
                                if (this.checkboxes[key].isCard) {
                                    this.checkboxes[key].currentPrice = this.checkboxes[key].price[this
                                        .cardPeriod];
                                } else {
                                    this.checkboxes[key].currentPrice = this.checkboxes[key].price;
                                }
                            }
                            arr.push(this.checkboxes[key].currentPrice)
                        } else {
                            this.checkboxes[key].currentPrice = 0;
                        }
                    //}
                });
                if (arr.length > 0) {
                    this.total = arr.reduce(function (sum, current) {
                        return sum + current;
                    });
                } else {
                    this.total = 0;
                }
            },
            handler($event) {
                $event.preventDefault();
                let name = $event.target.name;
                if ($event.target.name == undefined) {
                    name = $event.target.parentNode.name;
                    if ($event.target.parentNode.name == undefined) {
                        name = $event.target.parentNode.parentNode.name;
                    }
                }
                this.checkboxes[name].checked = !this.checkboxes[name].checked;
                this.discount();
            },
            updateWidth() {
                this.width = window.innerWidth;
            }
        },
        computed: {
            isMobile() {
                return this.width < 769
            },
            periodAdd() {
                return `${this.period}Add`
            },
            isTwoSmart() {
                let arr = [];
                Object.keys(this.checkboxes).forEach(key => {
                    if (this.checkboxes[key].isSmart) {
                        arr.push(this.checkboxes[key].checked)
                    }
                })

                let bool = arr.reduce(function (sum, current) {
                    return sum + current;
                });
                if (bool == 2) {
                    return true
                } else {
                    return false
                }
            },
            isMoreThenOne() {
                let arr = [];
                Object.keys(this.checkboxes).forEach(key => {
                    if (this.checkboxes[key].isMain) {
                        arr.push(this.checkboxes[key].checked)
                    }
                })

                let bool = arr.reduce(function (sum, current) {
                    return sum + current;
                });
                if (bool > 1) {
                    return true
                } else {
                    return false
                }
            },
            isSmartSelected() {
                let arr = [];
                Object.keys(this.checkboxes).forEach(key => {
                    if (this.checkboxes[key].isSmart) {
                        arr.push(this.checkboxes[key].checked)
                    }
                })

                let bool = arr.reduce(function (sum, current) {
                    return sum + current;
                });
                if (bool > 0) {
                    return true
                } else {
                    return false
                }
            },
            totalPeriodical() {
                let arr = [];
                Object.keys(this.checkboxes).forEach(key => {
                    if (this.checkboxes[key].group == 'period') {
                        arr.push(this.checkboxes[key].currentPrice)
                    }
                })

                let period = arr.reduce(function (sum, current) {
                    return sum + current;
                });

                return period
            },
            totalAdditional() {
                let arr = [];
                Object.keys(this.checkboxes).forEach(key => {
                    if (this.checkboxes[key].group == 'additional') {
                        arr.push(this.checkboxes[key].currentPrice)
                    }
                })

                let period = arr.reduce(function (sum, current) {
                    return sum + current;
                });

                return period
            },
            totalOnce() {
                let arr = [];
                Object.keys(this.checkboxes).forEach(key => {
                    if (this.checkboxes[key].group == 'once') {
                        arr.push(this.checkboxes[key].currentPrice)
                    }
                })

                let period = arr.reduce(function (sum, current) {
                    return sum + current;
                });

                return period
            },
            isDisabled() {
                if (this.total == 0) {
                    return true
                } else {
                    return false
                }
            }
        },
        created() {
            window.addEventListener('resize', this.updateWidth);
            this.updateWidth();
        }
    })
}
