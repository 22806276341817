import axios from 'axios';

const container = document.querySelector('.js-company-data');


function init() {
    if (!container) {
        return;
    }

    const clientsCount = container.querySelector('.js-clients-count');
    const purchasesCount = container.querySelector('.js-purchases-count');
    const creditBonusesCount = container.querySelector('.js-credit-bonuses-count');
    const debitBonusesCount = container.querySelector('.js-debit-bonuses-count');

    axios.get('https://bonusplus.pro/api/landing/getpromostats').then(res => {
        clientsCount.innerHTML = res.data.clientsCount.toLocaleString('ru');
        purchasesCount.innerHTML = res.data.purchasesCount.toLocaleString('ru');
        creditBonusesCount.innerHTML = res.data.creditBonusesCount.toLocaleString('ru');
        debitBonusesCount.innerHTML = res.data.debitBonusesCount.toLocaleString('ru');
    });
}

export default { init }
