export default function toggleRadio(event) {
    
    let previousValue = event.target.getAttribute('previousValue'),
        name = event.target.getAttribute('name');

    if (previousValue == 'checked') {
        event.target.checked = false;
        event.target.setAttribute('previousValue', false)
    } else {
        let radioArr = Array.from(document.querySelectorAll(`input[name=${name}]`));
        radioArr.forEach(function(radio){
            radio.setAttribute('previousValue', false)
        })
        event.target.setAttribute('previousValue', 'checked')
    }

}