import "scroll-behavior-polyfill";

let obj = {};

let sections = Array.from(document.querySelectorAll('.js-intersection'));

const just = document.querySelector('.just__bg'),
      header = document.querySelector('.header'),
      wrapper = document.querySelector('.js-wrapper-main'),
      tryFree = document.querySelector('.try-free'),
      progress = document.querySelector('.progress');

    let callback = function(cur) {
            if (cur.getAttribute('data-bg-color') === 'light') {
                wrapper?.classList.remove('dark')
                wrapper?.classList.add('light');
            } else {
                wrapper?.classList.add('dark')
                wrapper?.classList.remove('light');
            }

            if (cur.getAttribute('data-bg-color') === 'light' ) {
                    header.classList.remove('blue')
                    header.classList.remove('black')
                    header.classList.add('dark')
                    header.classList.add('light');
                    just?.classList.remove('color-dark')
                    just?.classList.remove('color-main')
                    just?.classList.add('color-light')
            } else if (cur.getAttribute('data-bg-color') === 'dark') {
                    header.classList.remove('dark')
                    header.classList.remove('blue')
                    header.classList.remove('light')
                    header.classList.add('black');
                    just?.classList.remove('color-light')
                    just?.classList.remove('color-main')
                    just?.classList.add('color-dark')
            } else {
                    header.classList.remove('dark')
                    header.classList.remove('black')
                    header.classList.remove('light')
                    header.classList.add('blue');
                    just?.classList.remove('color-light')
                    just?.classList.remove('color-dark')
                    just?.classList.add('color-main')
            }
            cur.classList.remove('is-hide')
            cur.classList.add('is-in')
            if (cur.classList.contains('js-section-13')) {
                tryFree?.classList.add('is-hide')
            } else {
                tryFree?.classList.remove('is-hide')
            }
        },
        observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {

                let cur = entry.target;
                if (entry.isIntersecting) {
                    if (entry.target.querySelector('.footer')) {
                        let footer = entry.target.querySelector('.footer');
                        if (!footer.classList.contains('is-in')) {
                            footer.classList.add('is-in');
                        }
                        progress.classList.add('is-up');
                    } else {
                        progress.classList.remove('is-up');
                    }
                    header.classList.add('is-in')
                    if (cur.getAttribute('data-bg-color') === 'light' ) {
                        header.classList.add('dark')
                        header.classList.add('light');
                        just?.classList.add('color-light')
                    } else if (cur.getAttribute('data-bg-color') === 'dark') {
                        header.classList.add('black');
                        just?.classList.add('color-dark')
                    } else {
                        header.classList.add('blue');
                        just?.classList.add('color-main')
                    }

                    callback(cur)
                }
            })
        },
        {
            threshold: [0, 1],
            rootMargin: '-60% 0% -40% 0%'
        }
        );

export default function intersection(unobserve) {
    sections.forEach(item => {
        observer.observe(item);
    })
}
