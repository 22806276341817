
var YouTubeIframeLoader = require('youtube-iframe');

export default function video() {
    let player,
        lastButton = '',
        btnPlay = Array.from(document.querySelectorAll('.js-video-button')),
        youtubeClose = Array.from(document.querySelectorAll('.js-youtubeClose'));
    const youtube = 'youtube-video';
    const videoQuality = 'hd720';




    function onYouTubePlayerAPIReady() {
        YouTubeIframeLoader.load(function(YT) {
            player = new YT.Player(youtube, {
                controls: 2,
                iv_load_policy: 3,
                rel: 0,
                events: {
                    onReady: onPlayerReady
                }
            });
        });
    }

    onYouTubePlayerAPIReady() 
    function onPlayerReady(event) {
    btnPlay.map(function(item){
        item.addEventListener('click', function() {
            let videoId = this.getAttribute('data-video-src');

            if (lastButton == videoId) {
                player.playVideo(videoId, 0, videoQuality)
            } else {
                player.loadVideoById(videoId, 0, videoQuality)
                lastButton = videoId;
            }
        })
    });
    
    youtubeClose.map(function(item){
        item.addEventListener('click', function() {
            player.pauseVideo();
        })
    })
    window.addEventListener('keyup', function (e) {
        if (e.keyCode == '27') {
            player.pauseVideo();
        }
    });

    }
}