export default function Accordeon(element) {
        let API = {},
            setting = {};
        if (!element) {
            return
        }

        let isMobile = false;

        if (window.innerWidth < 1170) {
            isMobile = true;
        }

        window.addEventListener('resize', function() {
            if (window.innerWidth < 1170) {
                isMobile = true;
            } else {
                isMobile = false;
            }
            if (isMobile) {
                element.addEventListener('click', toggle);
            } else {
                element.removeEventListener('click', toggle)
            }
        });

        function init() {
            let elements = Array.from(element.querySelectorAll('[data-accordeon]')),
                targets = Array.from(element.querySelectorAll('[data-accordeon-id]'));
            setting.elements = elements;
            setting.targets = targets;
        }

        function toggle(e) {
            e.preventDefault();
            e.stopPropagation();
            if (e.target.classList.contains('is-open') || e.target.classList.contains('blocks__close')) {
                if (e.target.getAttribute('data-accordeon') !== null) {
                    let id = e.target.getAttribute('data-accordeon');
                    let target = document.querySelector(`[data-accordeon-id=${id}]`),
                        elements = Array.from(document.querySelectorAll(`[data-accordeon=${id}]`));
                    target.classList.add('is-out');
                    elements.forEach(element => {
                        element.classList.add('is-out')
                    })
                    setTimeout(() => {
                        target.classList.remove('is-open')
                        target.classList.remove('is-out')
                        element.classList.remove('is-open')
                        elements.forEach(element => {
                            element.classList.remove('is-out')
                        })
                    }, 400)
                } else {
                    e.target.classList.add('is-out')
                    e.target.parentNode.classList.add('is-out')
                    setTimeout(() => {
                        e.target.classList.remove('is-open')
                        e.target.parentNode.classList.remove('is-open')
                        e.target.classList.remove('is-out');
                        e.target.parentNode.classList.remove('is-out');
                    }, 400)
                }
            } else {
                setting.elements.forEach(element => {
                    if (element.classList.contains('is-open')) {
                        element.classList.add('is-out')
                        setTimeout(() => {
                            element.classList.remove('is-open')
                            element.classList.remove('is-out')
                        }, 400)
                    } else {
                        element.classList.remove('is-open')
                        element.classList.remove('is-out')
                    }
                })
                setting.targets.forEach(target => {
                    if (target.classList.contains('is-open')) {
                        target.classList.add('is-out')
                        setTimeout(() => {
                            target.classList.remove('is-open')
                            target.classList.remove('is-out')
                        }, 400)
                    } else {
                        target.classList.remove('is-open')
                        target.classList.remove('is-out')
                        target.parentNode.classList.remove('is-open')
                        target.parentNode.classList.remove('is-out')
                    }

                })
                e.target.classList.add('is-open');
                e.target.parentNode.classList.add('is-open');
            }
        }

        function hideAll() {
            let group = Array.from(document.querySelectorAll(`[data-accordeon=${setting.selector}]`));
            group.forEach(item => {
                let target = item.querySelector('[data-accordeon-group]');
                if (target) {
                    if (target.classList.contains('is-open')) {
                        target.classList.remove('is-open')
                        target.classList.add('is-out')
                        setTimeout(()=> {
                            target.classList.remove('is-out');
                        }, 400)
                    }
                }

            })
        }
        function show(event) {
            event.preventDefault()
            setTimeout(() => {
                if (!setting.container.classList.contains('is-open')) {
                    setting.container.classList.add('is-open')
                } else {
                    hideAll();
                }
            }, 400)

        }

        init();

        let accordeonBlocks = document.querySelectorAll('[data-accordeon-group]');

        accordeonBlocks.forEach(block => {
            block.addEventListener('mouseleave', hideAll)
        })

        window.addEventListener('keyup', function (e) {
            if (e.keyCode == '27') {
                hideAll();
            }
        });

        return API;
    }