import ajaxLoader from '../components/ajaxLoader'

function init(selector, container, tabs, htmlContainer, html) {

    let filters = Array.from(document.querySelectorAll(`${container} ${tabs}:not(${tabs}--last)`)),
        htmlContainerEl = document.querySelector(htmlContainer);


    function removeActive() {
        filters.map(function(item){
            item.classList.remove('is-active')
        })
    }

    filters.map(function(tab, index){
        tab.addEventListener('click', function() {
            ajaxLoader(selector);
            removeActive()
            tab.classList.add('is-active')
        })
    })
}

const module = { init };

export default module;