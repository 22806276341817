export default function percent(event) {

    let container = document.querySelector('.js-wrapper-main');

    if (!container) {return}

    let body = document.querySelector('body'),
        html = document.querySelector('html'),
        height = Math.max( body.scrollHeight, body.offsetHeight,
            html.clientHeight, html.scrollHeight, html.offsetHeight ),
        currentScroll = height == html.clientHeight ? 100 : Math.round((window.scrollY) / (height -  html.clientHeight) * 100), //если высота страницы не больше 1 экрана, указывать текущее положение скролла 100
        progress = document.querySelector('.progress'),
        docHeight = html.clientHeight;
    progress.innerHTML = currentScroll + '%';
}